<template>
    <div>
        <div class="container py-8">
            <h1>Dashboard</h1>
            <ul>
                <li v-for="page in pages" :key="page.route">
                    <router-link v-if="page.route" :to="page.route">{{
                        page.name
                    }}</router-link>
                    <span v-else
                        ><b>{{ page.name }}</b> (coming soon)</span
                    >
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
// import { defineComponent } from "vue";

export default Vue.extend({
    data() {
        return {
            pages: [
                {
                    name: "Edit Sessions",
                    route: "/admin/sessions"
                },
                {
                    name: "Analytics",
                    route: "/admin/analytics"
                },
                {
                    name: "Edit Options",
                    route: "/admin/options"
                },
                {
                    name: "Superusers",
                    route: "/admin/superusers"
                },
                {
                    name: "Back to Site",
                    route: "/"
                }
            ]
        };
    }
});
</script>
